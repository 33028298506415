import { makeStyles, createStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) =>
  createStyles({
    center: {
      textAlign: "center",
      justifyContent: "center",
    },
    openBtn: {
      marginTop: "1.2rem",
      textAlign: "center",
      justifyContent: "center",
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    fabProgress: {
      color: green[500],
      position: "absolute",
      top: -6,
      left: -6,
      zIndex: 1,
    },
    loadingScan: {
      height: "0.5rem",
    },
    loadingContainer: {
      opacity: 0.4,
    },
    iconBtn: {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "#dddddd",
      },
    },
  })
);
export default useStyles;
