import React, { useState, useEffect } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
  Typography,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Grid,
} from "@material-ui/core";

import moment from "moment";

import useStyles from "./styles";

import shareBtn from "assets/img/pwaModel/share_button_safari.png";
import parkLogo from "assets/img/pwaModel/android-chrome-512x512.png";

let deferredPrompt;

export default function ResponsiveDialog() {
  let [showInstall, setShowInstall] = useState(false),
    [timer, setTimer] = useState(15),
    [showIOS, setShowIOS] = useState(false);
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    let iosTimer = window.localStorage.getItem("@Zigpark:iosModelTimer");
    if (navigator.standalone) {
      return false;
    }
    if (
      ["iPhone", "iPad", "iPod"].includes(navigator.platform) &&
      new Date() > new Date(iosTimer)
    ) {
      setShowIOS(true);
      return;
    }
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      if (
        window.localStorage.getItem("@Zigpark:showInstallModel") === "dismissed"
      ) {
        return;
      }
      setShowInstall(true);
    });
  }, []);

  const showPrompt = (e) => {
    // Hide the app provided install promotion
    //setShowInstall(false);
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
        window.location.reload();
      } else {
        console.log("User dismissed the install prompt");
      }
    });
  };

  const handleClose = () => {
    setShowInstall(false);
    window.localStorage.setItem("@Zigpark:showInstallModel", "dismissed");
  };

  const handleCloseIOS = () => {
    setShowIOS(false);
    //Configura a próxima vez que o sistema irá perguntar ao usuário
    window.localStorage.setItem(
      "@Zigpark:iosModelTimer",
      moment().add(1, "hours").toISOString()
    );
  };
  useEffect(() => {
    if (showIOS || showInstall) {
      setInterval(() => {
        setTimer((oldState) => {
          if (oldState <= 0) {
            return 0;
          }
          return oldState - 1;
        });
      }, 1000);
    }
  }, [showIOS, showInstall]);

  if (showIOS) {
    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          open={showIOS}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            <Grid container direction="row" alignItems="center" spacing={2}>
              <Grid item>
                <img
                  src={parkLogo}
                  className={classes.parkLogo}
                  alt="Logo Park Lockyt"
                ></img>
              </Grid>
              <Grid item>
                <Typography variant="h1" className={classes.title}>
                  Instale o Park Lockyt e obtenha vantagens!
                </Typography>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="body1"
              style={{ fontSize: 21.5 }}
              align="center"
            >
              É de graça e não ocupa espaço.
            </Typography>
            <Typography
              variant="body1"
              style={{ fontSize: 24, marginTop: "10%" }}
              align="center"
            >
              Para instalar clique no ícone{" "}
              <img
                alt="Botão compartilhar do safari"
                src={shareBtn}
                className={classes.shareIOS}
              />{" "}
              e depois em <b>adicionar à tela de início.</b>
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseIOS}
              color="primary"
              disabled={timer !== 0 ? true : false}
            >
              Não obrigado {timer !== 0 ? `(${timer}s)` : ""}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={showInstall}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <img
                src={parkLogo}
                className={classes.parkLogo}
                alt="Logo Park Lockyt"
              ></img>
            </Grid>
            <Grid item>
              <Typography variant="h1" className={classes.title}>
                Instale o Park Lockyt e obtenha vantagens!
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" style={{ fontSize: 24 }} align="center">
            É de graça e não ocupa espaço.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item>
              <Button
                onClick={handleClose}
                color="primary"
                disabled={timer !== 0 ? true : false}
              >
                Não obrigado {timer !== 0 ? `(${timer}s)` : ""}
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={showPrompt}
                color="primary"
                variant="contained"
                style={{ fontSize: "4.3vw" }}
              >
                Sim instalar
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
