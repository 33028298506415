import React, { useState, useEffect } from "react";
import {
  Grid,
  LinearProgress,
  Button,
  Card,
  CardContent,
  Typography,
  FormControl,
  Input,
  InputLabel,
  FormGroup,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import axios from "config/axios";
import fire from "config/firebase";

import ErrorIcon from "@material-ui/icons/Error";

import image from "assets/img/bg.jpg";

import validaCPF from "functions/validateCPF";

//custom components
import Snackbar from "components/Snackbar/Snackbar.js";
import InstallPWA from "components/InstallPWA/InstallPWA";

import useStyles from "./styles";

function Login() {
  let [loading, setLoading] = useState(false),
    [email, setEmail] = useState(""),
    [registerName, setRegisterName] = useState(""),
    [registerEmail, setRegisterEmail] = useState(""),
    [registerDocument, setRegisterDocument] = useState(""),
    [registerCell, setRegisterCell] = useState(""),
    [hasCellphone, setHasCellphone] = useState(""),
    [registerUid, setRegisterUid] = useState(""),
    [register, setRegister] = useState(false),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [password, setPassword] = useState("");

  let history = useHistory(),
    location = useLocation(),
    classes = useStyles();

  const loginGoogle = async () => {
    setLoading(true);
    fire
      .googleLogin()
      .then((val) => {
        axios
          .post("/check/account", {
            firebaseUid: val.user.uid,
          })
          .then(() => {
            axios
              .post("/login", {
                firebaseUid: val.user.uid,
              })
              .then(({ data }) => {
                setLoading(false);
                window.localStorage.setItem("@Zigpark:token", data.token);
                window.localStorage.setItem("@Zigpark:name", data?.user?.name);
                history.push("/admin/dashboard");
              })
              .catch((error) => {
                window.scrollTo(0, 0);
                setLoading(false);
                console.log(error);
                setAlert({
                  alert: true,
                  msg: "Problema ao entrar com o google, tente novamente",
                  color: "danger",
                });
              });
          })
          .catch((error) => {
            setLoading(false);
            window.scrollTo(0, 0);
            switch (error?.response?.status) {
              case 404:
                let { displayName, email, phoneNumber, uid } = val.user;
                /* Seta os dados obtidos pelo firebase */
                setRegisterName(displayName);
                setRegisterEmail(email);
                if (phoneNumber) {
                  setRegisterCell(phoneNumber);
                  setHasCellphone(true);
                } else {
                  setHasCellphone(false);
                }
                setRegisterUid(uid);
                setRegister(true);
                break;
              default:
                setAlert({
                  alert: true,
                  msg: "Problema ao entrar com o google, tente novamente",
                  color: "danger",
                });
                break;
            }
          });
      })
      .catch((error) => {
        console.log(error, "fire");
        setLoading(false);
        window.scrollTo(0, 0);
        switch (error?.code) {
          case "auth/unauthorized-domain":
            setAlert({
              alert: true,
              msg: "Domínio não autorizado",
              color: "danger",
            });
            break;
          case "auth/cancelled-popup-request":
            return;
          case "auth/popup-closed-by-user":
            return;
          case "auth/network-request-failed":
            setAlert({
              alert: true,
              msg: "Se conecte a internet para fazer login",
              color: "danger",
            });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao entrar com o google, tente novamente",
              color: "danger",
            });
            break;
        }
      });
  };

  useEffect(() => {
    if (location?.state?.emailSent) {
      location.state = null;
      setAlert({
        alert: true,
        msg: "Email de verifação enviado, olhe sua caixa de entrada",
        color: "success",
      });
    }
    if (location?.state?.resetSent) {
      location.state = null;
      setAlert({
        alert: true,
        msg: "Email de troca de senha enviado, olhe sua caixa de entrada",
        color: "success",
      });
    }
  }, [location]);

  const verify = async (e) => {
    e.preventDefault();
    setLoading(true);
    fire
      .emailLogin(email, password)
      .then((val) => {
        if (!val.user.emailVerified) {
          setLoading(false);
          return setAlert({
            alert: true,
            msg: "Email não verificado",
            color: "danger",
          });
        }
        axios
          .post("/login", {
            firebaseUid: val.user.uid,
          })
          .then(({ data }) => {
            setLoading(false);
            window.localStorage.setItem("@Zigpark:token", data.token);
            window.localStorage.setItem("@Zigpark:name", data?.user?.name);
            history.push("/admin/dashboard");
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            window.scrollTo(0, 0);
            switch (error?.response?.status) {
              case 400:
                setAlert({
                  alert: true,
                  msg: error?.response?.data.error,
                  color: "danger",
                });
                break;
              case 401:
                setAlert({
                  alert: true,
                  msg:
                    "Conta não ativada, clique no link enviado para seu email",
                  color: "danger",
                });
                break;
              default:
                setAlert({
                  alert: true,
                  msg: "Problema ao entrar, tente novamente",
                  color: "danger",
                });
                break;
            }
          });
      })
      .catch((error) => {
        console.log(error, "fire");
        setLoading(false);
        window.scrollTo(0, 0);
        switch (error?.code) {
          case "auth/wrong-password":
            setAlert({
              alert: true,
              msg: "Senha incorreta",
              color: "danger",
            });
            break;
          case "auth/user-not-found":
            setAlert({
              alert: true,
              msg: "Usuário não encontrado! CADASTRE-SE no Park Lockyt",
              color: "danger",
            });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao entrar, tente novamente",
              color: "danger",
            });
            break;
        }
      });
  };

  const procCpf = (e) => {
    let cpf = e.target.value;
    cpf = cpf.replace(/\D/g, "");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2");
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    setRegisterDocument(cpf);
  };

  const completeRegister = async (e) => {
    e.preventDefault();
    if (
      !registerCell ||
      !registerDocument ||
      !registerEmail ||
      !registerName ||
      !registerUid
    ) {
      window.scrollTo(0, 0);
      return setAlert({ alert: true, msg: "Preencha todos os campos" });
    }
    if (!(await validaCPF(registerDocument))) {
      window.scrollTo(0, 0);
      return setAlert({ alert: true, msg: "CPF inválido" });
    }
    let realCell = registerCell.replace(/\D/g, "");
    if (realCell.length < 11) {
      window.scrollTo(0, 0);
      return setAlert({ alert: true, msg: "Telefone inválido" });
    }
    setLoading(true);
    axios
      .post("/register", {
        email: registerEmail,
        name: registerName,
        cellphone: "+55" + realCell,
        firebaseUid: registerUid,
        document: await validaCPF(registerDocument),
        generate: true,
      })
      .then(({ data }) => {
        setLoading(false);
        window.localStorage.setItem("@Zigpark:token", data.token);
        history.push("/admin/dashboard");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setAlert({
          alert: true,
          msg: "Problema ao entrar com o google, tente novamente",
          color: "danger",
        });
      });
  };

  const maskCel = async (e) => {
    let v = e.target.value;
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    if (v.length > 11) {
      return;
    }
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
    setRegisterCell(v);
  };

  return (
    <div
      className={classes.backgroundImage}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <div>
        {loading ? <LinearProgress color="secondary" /> : <></>}
        {alert.alert && (
          <Snackbar
            place="tc"
            color={alert.color}
            icon={ErrorIcon}
            message={alert.msg}
            open={alert.alert}
            closeNotification={() =>
              setAlert({ alert: false, msg: "", color: "sucess" })
            }
            close
          />
        )}
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          className={classes.logos}
        >
          <Grid item className={classes.gridLogos}>
            <a
              href="https://park.lockyt.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("assets/img/zigpark.png")}
                alt="Logomarca park lockyt"
                className={classes.logoLockyt}
              />
            </a>
          </Grid>
          <Grid item style={{ marginRight: "2%" }}>
            <InstallPWA btn={{ className: classes.installPWA }} />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <Card className={classes.root}>
              <CardContent>
                {register ? (
                  <form onSubmit={completeRegister}>
                    <Typography
                      variant="h4"
                      style={{ marginBottom: "1rem" }}
                      align="center"
                    >
                      Completar cadastro
                    </Typography>
                    <FormGroup>
                      <FormControl className={classes.input}>
                        <InputLabel htmlFor="cpf">CPF </InputLabel>
                        <Input
                          id="cpf"
                          type="text"
                          value={registerDocument}
                          disabled={loading}
                          onChange={procCpf}
                        />
                      </FormControl>
                      {!hasCellphone && (
                        <FormControl className={classes.input}>
                          <InputLabel htmlFor="cellphone">
                            Celular com DDD
                          </InputLabel>
                          <Input
                            id="cellphone"
                            type="tel"
                            value={registerCell}
                            disabled={loading}
                            onChange={maskCel}
                          />
                        </FormControl>
                      )}
                      <Button
                        size="small"
                        className={classes.btn}
                        type="submit"
                      >
                        Terminar cadastro
                      </Button>
                    </FormGroup>
                  </form>
                ) : (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <Typography variant="h4" style={{ marginBottom: "1rem" }}>
                        Entrar
                      </Typography>
                      <Button
                        size="small"
                        className={classes.btnGoogleContainer}
                        onClick={loginGoogle}
                      >
                        <img
                          src={require("assets/img/auth/btn_google.png")}
                          className={classes.btnGoogle}
                          alt="Login com google"
                        />
                      </Button>
                    </div>
                    <form onSubmit={verify}>
                      <FormGroup>
                        <FormControl className={classes.input}>
                          <InputLabel htmlFor="email">Email </InputLabel>
                          <Input
                            id="email"
                            type="email"
                            value={email}
                            disabled={loading}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormControl>
                        <FormControl className={classes.input}>
                          <InputLabel htmlFor="password">Senha </InputLabel>
                          <Input
                            id="password"
                            type="password"
                            value={password}
                            disabled={loading}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </FormControl>
                        <Button
                          size="small"
                          className={classes.btn}
                          type="submit"
                        >
                          Entrar
                        </Button>
                        <div className={classes.btnSecondaryContainer}>
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                          >
                            ou
                          </Typography>
                          <Button
                            size="small"
                            className={classes.btnSecondary}
                            onClick={() => history.push("/register")}
                            variant="contained"
                            color="secondary"
                          >
                            Cadastre-se
                          </Button>
                        </div>
                        <Button
                          size="small"
                          className={classes.btnForgot}
                          onClick={() => history.push("/forgot/password")}
                        >
                          <Typography variant="body2">
                            Esqueceu sua senha?
                          </Typography>
                        </Button>
                      </FormGroup>
                    </form>
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      {/*<Footer />*/}
    </div>
  );
}

export default Login;
