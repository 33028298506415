import React, { useState, useEffect } from "react";

// @material-ui/core
import { Paper, IconButton, Typography, Backdrop } from "@material-ui/core";
import QrReader from "react-qr-reader";
//import { makeStyles } from "@material-ui/core/styles";

//import axios from "config/axios";

// @material-ui/icons
import ErrorIcon from "@material-ui/icons/Error";
import LocalOffer from "@material-ui/icons/LocalOffer";
import Cached from "@material-ui/icons/Cached";

import moment from "moment";
import { ScaleLoader } from "react-spinners";

// custom components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Snackbar from "components/Snackbar/Snackbar.js";
import InstallPWAModel from "components/InstallPWAModel/InstallPWAModel.js";

//import { requestNotifyPermission } from "config/firebase";
import axios from "config/axios";

//import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

//const useStyles = makeStyles(styles);
import useStyles from "./styles";

//Importando usando o lazy
/* let ReceivedPromotion = lazy(() =>
  import("components/ReceivedPromotion/ReceivedPromotion")
); */

export default function Dashboard() {
  const [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [alertSuccess, setAlertSuccess] = useState({
      alert: false,
      msg: "",
    }),
    [alertPromotion, setAlertPromotion] = useState(""),
    [loading, setLoading] = useState(false),
    [disableCamera, setDisableCamera] = useState(false),
    [hasPermission, setHasPermission] = useState(true),
    [userInfo, setUserInfo] = useState(),
    [problem, setProblem] = useState(),
    [cords, setCords] = useState(),
    [gpsError, setGpsError] = useState(false),
    [disableScanner, setDisableScanner] = useState(false);

  const classes = useStyles();

  const handleError = async (e) => {
    console.log(e);
    setProblem({
      msg: "Recarregue a página.",
      subtitle: "Navegadores indicados: Google Chrome e Safari",
    });
  };

  const clearAlerts = async () => {
    setAlert({ alert: false, msg: "" });
    setAlertSuccess({ alert: false, msg: "" });
  };

  const handleScan = async (data) => {
    if (data) {
      setDisableScanner(true);
      setLoading(true);
      let checkPay = data.split(":");
      if (checkPay[0] === "pay") {
        setLoading(true);
        return axios
          .post("/user/pay/park", {
            parkUuid: checkPay[1],
          })
          .then(({ data }) => {
            window.scrollTo(0, 0);
            clearAlerts();
            setLoading(false);
            setTimeout(() => {
              setDisableScanner(false);
            }, 8000);
            let timeString;
            if (data.time === 15) {
              timeString = "15 minutos";
            }
            let time = (data.time / 60).toFixed(2);
            timeString = `${Math.floor(time) || 0} h e ${
              ((time - Math.floor(time)) * 60).toFixed(0) || 0
            } min`;
            setAlertSuccess({
              alert: true,
              msg: `Pago com sucesso! Você pode sair em até ${timeString}. Total pago: ${new Intl.NumberFormat(
                "pt-BR",
                {
                  style: "currency",
                  currency: "BRL",
                }
              ).format(data.payed || 0)}`,
            });
          })
          .catch((error) => {
            window.scrollTo(0, 0);
            clearAlerts();
            setLoading(false);
            setTimeout(() => {
              setDisableScanner(false);
            }, 4000);
            console.log(error);
            switch (error?.response?.status) {
              case 400:
                switch (error?.response?.data?.status) {
                  case 1:
                    setAlert({
                      alert: true,
                      msg: "Você é mensalista",
                    });
                    break;
                  case 2:
                    setAlert({
                      alert: true,
                      msg: "Problema ao realizar pagamento",
                    });
                    break;
                  case 3:
                    setAlert({
                      alert: true,
                      msg: "Sem cartão cadastrado",
                    });
                    break;
                  default:
                    break;
                }
                break;
              case 404:
                setAlert({
                  alert: true,
                  msg: "Usuário não encontrado",
                });
                break;
              case 418:
                setAlert({
                  alert: true,
                  msg: `Créditos insuficientes. Valor a pagar ${new Intl.NumberFormat(
                    "pt-BR",
                    {
                      style: "currency",
                      currency: "BRL",
                    }
                  ).format(error.response.data.value)}`,
                });
                break;
              case 303:
                setAlert({
                  alert: true,
                  msg: `Dentro dos 15min. Tempo restante: ${error.response.data.remainTime}min`,
                });
                break;
              case 403:
                setAlert({
                  alert: true,
                  msg: "Entrada no estacionamento não registrada!",
                });
                break;

              case 401:
                setAlert({
                  alert: true,
                  msg: "Estacionamento não encontrado",
                });
                break;
              case 402:
                setAlert({
                  alert: true,
                  msg: "Estacionamento já pago",
                });
                break;
              default:
                setAlert({
                  alert: true,
                  msg: "Problema ao pagar",
                });
                break;
            }
          });
      }
      //console.log(data);
      axios
        .post("/action/open", {
          uuid: data,
          lat: cords?.lat,
          long: cords?.long,
        })
        .then(({ data }) => {
          window.scrollTo(0, 0);
          clearAlerts();
          setTimeout(() => {
            setDisableScanner(false);
          }, 8000);
          setLoading(false);
          getUserParkInfo();
          if (data.state === "out") {
            setAlertSuccess({
              alert: true,
              msg: `Volte sempre ao ${data.park.name}`,
            });
          } else {
            setAlertSuccess({
              alert: true,
              msg: `Bem vindo ao ${data.park.name}`,
            });
          }
          //Verifica se está entrando com promoção
          if (data.promotion && data.state !== "out") {
            //Espera um pouco para dizer que tem promoção
            //Para não ficar muita informação na tela do usuário
            setTimeout(() => {
              setAlertPromotion(
                `Desconto de ${(data?.promotion?.value * 100).toFixed(
                  0
                )}% ativo`
              );
            }, 700);
          }
        })
        .catch((error) => {
          window.scrollTo(0, 0);
          clearAlerts();
          setTimeout(() => {
            setDisableScanner(false);
          }, 4000);
          setLoading(false);
          console.log(error);
          switch (error?.response?.status) {
            case 420:
              setAlert({
                alert: true,
                msg: "Você está longe do local",
              });
              break;
            case 404:
              setAlert({
                alert: true,
                msg: "Cancela não encontrada",
              });
              break;
            case 400:
              switch (error?.response?.data?.status) {
                case 408:
                  setAlert({
                    alert: true,
                    msg: "Problema de comunicação",
                  });
                  break;
                case 1:
                  setAlert({
                    alert: true,
                    msg: "Veículo não detectado",
                  });
                  break;
                default:
                  break;
              }
              break;
            case 405:
              setAlert({
                alert: true,
                msg: "É preciso entrar primeiro",
              });
              break;
            case 406:
              setAlert({
                alert: true,
                msg: "Pagamento não encontrado",
              });
              break;
            case 418:
              setAlert({
                alert: true,
                msg:
                  "Prazo de saída expirado, se dirija a administração do local",
              });
              break;
            case 401:
              setAlert({
                alert: true,
                msg: "Não é possível entrar 2 vezes",
              });
              break;
            default:
              setAlert({
                alert: true,
                msg: "Problema ao abrir",
              });
              break;
          }
        });
    }
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      /* geolocation is available */
      navigator.geolocation.watchPosition(
        function (position) {
          setCords({
            lat: position.coords.latitude,
            long: position.coords.longitude,
            accu: position.coords.accuracy,
          });
        },
        (error) => {
          console.log(error);
          if (error.code === 1) {
            setGpsError(true);
          }
        },
        { enableHighAccuracy: true, maximumAge: 30000, timeout: 27000 }
      );
    } else {
      console.log("Navegador não suporta localização");
      setAlert({
        alert: true,
        msg: "Seu navegador não suporta localização tente em outro navegador",
      });
      setGpsError(true);
    }
    /* if ("permissions" in navigator) {
      navigator.permissions
        .query({ name: "camera" })
        .then(() => {
          setHasPermission(true);
        })
        .catch((error) => {
          console.log(error);
          setHasPermission(false);
        });
    } */
  }, []);

  const getUserParkInfo = async () => {
    axios
      .get("/user/park/info")
      .then(({ data }) => {
        setUserInfo(data);
        if (data.promotion) {
          setAlertPromotion(
            `Promoção de ${(data?.promotion?.value * 100).toFixed(0)}% ativa`
          );
        } else {
          setAlertPromotion("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserParkInfo();
  }, []);

  const procRefresh = async () => {
    setDisableCamera(true);
    setTimeout(() => {
      setDisableCamera(false);
    }, 100);
  };

  if (problem) {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Paper style={{ padding: 10 }}>
            <Typography variant="h5" align="center">
              {problem.msg}
            </Typography>
            <Typography variant="body1" align="center">
              {problem?.subtitle}
            </Typography>
          </Paper>
        </GridItem>
      </GridContainer>
    );
  }

  if (!hasPermission) {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Paper style={{ padding: 10 }}>
            <Typography variant="h5" align="center">
              Sem permissão para usar a câmera
            </Typography>
          </Paper>
        </GridItem>
      </GridContainer>
    );
  }

  if (gpsError) {
    return (
      <GridContainer>
        <GridItem xs={12}>
          <Paper style={{ padding: 10 }}>
            <Typography variant="h5" align="center">
              O GPS é necessário para usar o app
            </Typography>
          </Paper>
        </GridItem>
      </GridContainer>
    );
  }

  if (loading) {
    return (
      <Backdrop open={loading}>
        <Paper style={{ padding: 10 }}>
          <Typography variant="body1" style={{ fontSize: 25 }} align="center">
            Obtendo comunicação
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ScaleLoader
              height={35}
              width={4}
              margin={2}
              radius={10}
              color="#05b5a9"
              loading={loading}
            />
          </div>
        </Paper>
      </Backdrop>
    );
  }

  return (
    <div>
      <InstallPWAModel />
      {/*  <Suspense fallback={<></>}>
        <ReceivedPromotion />
      </Suspense> */}
      <Snackbar
        place="tc"
        color="success"
        icon={ErrorIcon}
        message={alertSuccess.msg}
        open={alertSuccess.alert}
        closeNotification={() => setAlertSuccess({ alert: false, msg: "" })}
        close
      />
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={alert.msg}
        open={alert.alert}
        closeNotification={() => setAlert({ alert: false, msg: "" })}
        close
      />
      <Snackbar
        place="bc"
        color="success"
        icon={LocalOffer}
        message={alertPromotion ? alertPromotion : ""}
        open={alertPromotion ? true : false}
      />
      <GridContainer>
        {userInfo?.in && (
          <GridItem xs={12} style={{ marginBottom: 10 }}>
            <Typography variant="body1" align="center">
              Entrada em {userInfo?.park?.name} às{" "}
              {moment(userInfo?.in).format("HH:mm")}
            </Typography>
          </GridItem>
        )}
        <GridItem xs={12}>
          <Paper style={{ padding: 5 }}>
            <div style={{ backgroundColor: "black" }}>
              <div className={loading ? classes.loadingContainer : null}>
                {!disableCamera && (
                  <QrReader
                    delay={10}
                    onError={handleError}
                    onScan={
                      disableScanner
                        ? (data) => {
                            if (data) {
                              //console.log("disabled", data);
                            }
                          }
                        : handleScan
                    }
                    style={{ width: "100%" }}
                  />
                )}
              </div>
            </div>
          </Paper>
        </GridItem>
        <GridItem xs={12} style={{ textAlign: "center", marginTop: 10 }}>
          <IconButton onClick={procRefresh} className={classes.iconBtn}>
            <Cached style={{ fontSize: 30 }} color="primary" />
          </IconButton>
        </GridItem>
        {cords?.accu > 50 && (
          <GridItem xs={12}>
            <Paper>
              <Typography
                variant="h5"
                align="center"
                style={{ padding: "1.2rem", marginTop: 10 }}
              >
                Sinal de GPS fraco
              </Typography>
            </Paper>
          </GridItem>
        )}
      </GridContainer>
    </div>
  );
}
