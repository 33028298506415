import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    shareIOS: {
      width: "7vw",
    },
    parkLogo: {
      width: "16vw",
    },
    title: {
      fontSize: 32,
      fontWeight: "bold",
    },

  })
);

export default useStyles;
