import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core";

let deferredPrompt;

function InstallPWA(props) {
  let [showInstall, setShowInstall] = useState(false);
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      setShowInstall(true);
    });
  }, []);

  const showPrompt = (e) => {
    // Show the install prompt
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === "accepted") {
        console.log("User accepted the install prompt");
        setShowInstall(false);
      } else {
        console.log("User dismissed the install prompt");
      }
    });
  };

  return (
    showInstall &&
    !window.standalone && (
      <div {...props.btnContainer}>
        <Button onClick={showPrompt} {...props.btn}>
          Web App
        </Button>
      </div>
    )
  );
}

export default InstallPWA;
