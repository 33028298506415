/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import mainTheme from "./theme";
import * as serviceWorker from "./serviceWorker";

// core components
import Admin from "layouts/Admin.js";

import Login from "views/Login/Login";
import Register from "views/Register/Register";
import ForgotPassword from "views/ForgotPassword/ForgotPassword";
import Profile from "views/Profile/Profile";

import Support from "views/Support/Support";
//import RTL from "layouts/RTL.js";

import "assets/css/material-dashboard-react.css?v=1.9.0";
import "assets/scss/mainScss.scss?v=1.8.0";

import isAuthenticated from "functions/isAuthenticated";

const hist = createBrowserHistory();

// eslint-disable-next-line react/prop-types
function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={() => (isAuthenticated() ? children : <Redirect to="/" />)}
    />
  );
}

ReactDOM.render(
  <ThemeProvider theme={mainTheme}>
    <Router history={hist}>
      <Switch>
        <PrivateRoute path="/admin/*">
          <Route component={Admin} />
        </PrivateRoute>
        <PrivateRoute path="/config/profile">
          <Route component={Profile} />
        </PrivateRoute>
        {/* <Route path="/rtl" component={RTL} /> */}
        <Route path="/register" component={Register} />
        <Route path="/forgot/password" component={ForgotPassword} />
        <Route path="/support" component={Support} />
        {isAuthenticated() && <Redirect to="/admin/dashboard" />}
        <Route path="/" component={Login} />
      </Switch>
    </Router>
  </ThemeProvider>,
  document.getElementById("root")
);

serviceWorker.register();
