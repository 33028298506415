//Processa as bandeiras do cartão para o padrão da cielo

export default (brand) => {
  switch (brand) {
    case "mastercard":
      return "Master";
    default:
      return brand;
  }
};
