import * as firebase from "firebase/app";

import "firebase/auth";
import "firebase/firebase-messaging";
import "firebase/performance";
import "firebase/firebase-analytics";

import isAuthenticated from "functions/isAuthenticated";
import axios from "config/axios";

//import axios from "config/axios";
//import * as userToken from "functions/userToken";

export const requestNotifyPermission = async () => {
  try {
    await Notification.requestPermission();
  } catch (error) {
    console.log(error);
  }
};

class Fire {
  constructor() {
    let firebaseConfig = {
      apiKey: "AIzaSyDR2M52PXVGYyWc6Jm5K58Rep0vAcJh2-M",
      authDomain: "zigpark-120b0.firebaseapp.com",
      databaseURL: "https://zigpark-120b0.firebaseio.com",
      projectId: "zigpark-120b0",
      storageBucket: "zigpark-120b0.appspot.com",
      messagingSenderId: "525818089760",
      appId: "1:525818089760:web:a326f64ed29113b278c745",
      measurementId: "G-FPL9B4DDJH",
    };

    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    firebase.performance();
    if (!isAuthenticated()) {
      return null;
    }
    requestNotifyPermission();
    /* firebase.messaging().onMessage((e) => {
      let { promotion, park } = e?.data;
      if (promotion && park) {
        window.sessionStorage.setItem(
          "@Zigpark:promotion",
          JSON.stringify({ promotion, park })
        );
      }
    }); */

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js", { scope: "/admin/" })
        .then(async (registration) => {
          //console.log(registration);
          firebase.messaging().useServiceWorker(registration);

          let messaging = firebase.messaging();
          const token = await messaging.getToken();

          messaging.onTokenRefresh(async (next) => {
            await axios.put("/user/update/fcm", {
              fcmToken: await messaging.getToken(),
            });
            next();
          });

          if (process.env.NODE_ENV === "development") {
            console.log(token);
          } else {
            await axios.put("/user/update/fcm", {
              fcmToken: token,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    /* navigator.serviceWorker
      .register("/firebase-messaging-sw.js")
      .then(async (registration) => {
        firebase.messaging().useServiceWorker(registration);
        const token = await firebase.messaging().getToken();
        await axios
          .put(
            "/user/update/fcm",
            {
              fcmToken: token,
            },
            {
              headers: {
                Authorization: "bearer " + userToken.get(),
              },
            }
          )
          .catch((error) => {
            console.log(error);
          });
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          installingWorker.onstatechange = () => {
            if (installingWorker.state === "installed") {
              if (navigator.serviceWorker.controller) {
                // At this point, the updated precached content has been fetched,
                // but the previous service worker will still serve the older
                // content until all client tabs are closed.
                console.log("Novo serviceWorker do firebase encontrado");
              } else {
                // At this point, everything has been precached.
                // It's the perfect time to display a
                // "Content is cached for offline use." message.
                console.log("Pronto para pegar FCM");
              }
            }
          };
        };
      }); */
  }
  async googleLogin() {
    let provider = new firebase.auth.GoogleAuthProvider();
    /* Api para leitura dos números de telefone */
    //provider.addScope("https://www.googleapis.com/auth/user.phonenumbers.read");
    /* Lendo o gênero da pessoa */
    //provider.addScope("https://www.googleapis.com/auth/user.gender.read");
    /* Lendo os endereços dela */
    //provider.addScope("https://www.googleapis.com/auth/user.addresses.read");
    /* lendo a data de aniversário dela  */
    //provider.addScope("https://www.googleapis.com/auth/user.addresses.read");

    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(function (result) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        let token = result.credential.accessToken;
        // The signed-in user info.
        let user = result.user;
        return { user, token };
        // ...
      });
  }
  async emailRegister(email, password) {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }
  async sendVerifyEmail() {
    return firebase.auth().currentUser.sendEmailVerification();
  }
  async emailLogin(email, password) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((val) => {
        return val;
      });
  }
  async passwordReset(email) {
    return firebase.auth().sendPasswordResetEmail(email);
  }
}

export default new Fire();
