import React, { useState, useEffect } from "react";
import axios from "config/axios";

/* Material UI icons */
import ErrorIcon from "@material-ui/icons/Error";
import GiftCard from "@material-ui/icons/CardGiftcard";
import LocalOffer from "@material-ui/icons/LocalOffer";

//Material UI Core
import {
  Typography,
  Button,
  Grid,
  Paper,
  Icon,
  TextField,
  CircularProgress,
} from "@material-ui/core";

//Custom components
import Snackbar from "components/Snackbar/Snackbar.js";

import useStyles from "./styles";

function Payment() {
  const [voucherCod, setVoucherCod] = useState(""),
    [loading, setLoading] = useState(false),
    [alert, setAlert] = useState(""),
    [alertFooter, setAlertFooter] = useState(""),
    [, setUserParkInfo] = useState(),
    [alertSuccess, setAlertSuccess] = useState("");
  const classes = useStyles();

  const checkVoucher = async () => {
    if (!voucherCod) {
      return setAlert("Preencha o campo para continuar");
    }
    setLoading((oldState) => !oldState);
    axios
      .post("/user/validate/voucher", {
        voucherCod,
      })
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setVoucherCod("");
        let { value, unity } = data;
        switch (unity) {
          case "minute":
            unity = "min.";
            break;
          case "hour":
            unity = "h.";
            break;
          default:
            unity = "min.";
            break;
        }
        setAlertSuccess(
          `Voucher validado com sucesso! Desconto de ${value} ${unity}`
        );
        getUserParkInfo();
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.status) {
          case 1:
            setAlert("Voucher não encontrado");
            break;
          case 2:
            setAlert(
              `Entrada no estacionamento ${error?.response?.data?.parkName} não encontrada`
            );
            break;
          case 3:
            setAlert("Voucher inválido");
            break;
          case 4:
            setAlert("Não é possível usar um voucher sendo mensalista");
            break;
          case 5:
            setAlert("Você já possui um voucher ativo");
            break;
          case 6:
            setAlert("Não foi possível inserir voucher");
            break;
          case 7:
            setAlert("Voucher vencido");
            break;
          case 8:
            setAlert("Não é possível ativar 2 descontos");
            break;
          default:
            setAlert("Problema ao validar voucher");
            break;
        }
      });
  };

  const getUserParkInfo = async () => {
    axios
      .get("/user/park/info")
      .then(({ data }) => {
        setUserParkInfo(data);
        console.log(data);
        if (data.voucher) {
          let { value, unity } = data.voucher;
          switch (unity) {
            case "minute":
              unity = "min.";
              break;
            case "hour":
              unity = "h.";
              break;
            default:
              unity = "min.";
              break;
          }
          setAlertFooter(`Voucher ativo - ${value} ${unity}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserParkInfo();
  }, []);

  //UseEffects para limpar automáticamente os alertas opostos
  useEffect(() => {
    setAlertSuccess("");
  }, [alert]);

  useEffect(() => {
    setAlert("");
  }, [alertSuccess]);

  const procVoucherInput = async (e) => {
    let { value } = e.currentTarget;
    if (value.length > 8) {
      return;
    }
    value = value.toLowerCase();
    setVoucherCod(value);
  };

  return (
    <div>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={alert}
        open={alert ? true : false}
        closeNotification={() => setAlert("")}
        close
      />
      <Snackbar
        place="tc"
        color="success"
        icon={ErrorIcon}
        message={alertSuccess}
        open={alertSuccess ? true : false}
        closeNotification={() => setAlertSuccess("")}
        close
      />
      <Snackbar
        place="bc"
        color="success"
        icon={LocalOffer}
        message={alertFooter ? alertFooter : ""}
        open={alertFooter ? true : false}
      />
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={3}
            >
              <Grid item style={{ marginTop: 8 }}>
                <Icon>
                  <GiftCard />
                </Icon>
              </Grid>
              <Grid item>
                <Typography variant="h5">
                  Utilizar Voucher de desconto
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Código do voucher"
                  value={voucherCod}
                  onChange={procVoucherInput}
                />
              </Grid>
              <Grid item>
                <div className={classes.wrapper}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={checkVoucher}
                    disabled={loading}
                    size="large"
                  >
                    Utilizar
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default Payment;
