import React from "react";
import { Grid, Card, CardContent } from "@material-ui/core";

import image from "assets/img/bg.jpg";

//custom components
import InstallPWA from "components/InstallPWA/InstallPWA";

import useStyles from "./styles";

function Support() {
  const classes = useStyles();

  return (
    <div
      className={classes.backgroundImage}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <div>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          className={classes.logos}
        >
          <Grid item className={classes.gridLogos}>
            <a
              href="https://park.lockyt.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("assets/img/zigpark.png")}
                alt="Logomarca park lockyt"
                className={classes.logoLockyt}
              />
            </a>
          </Grid>
          <Grid item style={{ marginRight: "2%" }}>
            <InstallPWA btn={{ className: classes.installPWA }} />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <Card className={classes.root}>
              <CardContent>
                <h2>Estacionar se tornou uma nova experiência! </h2>
                <p>
                  Não se assuste com mensalidades, só pague o que usar! Nunca
                  mais perca tempo em filas, opere diretamente do seu smartphone
                  e realize pagamentos, ganhe promoções e insira descontos.
                </p>{" "}
                <ol>
                  <li className={classes.bold}>
                    Baixe nosso App pelas lojas do seu dispositivo ou opere
                    diretamente pela nossa plataforma (WEB APP) no site{" "}
                    <a
                      href={window.location.origin}
                      style={{
                        textTransform: "lowercase",
                        fontWeight: "bold",
                      }}
                    >
                      https://park.lockyt.com
                    </a>
                    ;
                  </li>
                  <li className={classes.bold}>
                    Insira seu cartão de crédito para realizar pagamentos; Agora
                    é só apontar a câmera do App para o QRcode e aguardar
                    validação para a operação;
                  </li>
                  <li className={classes.bold}>
                    Não se esqueça de checar a regra de cobrança de cada
                    localidade e ao final da estadia leia o Qr code para
                    pagamento e o Qr code de saída.
                  </li>
                </ol>
                <p>
                  Para dúvidas, questionamentos ou sugestões, acesse{" "}
                  <a
                    href="https://microsistemas.com.br"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textTransform: "lowercase",
                      fontWeight: "bold",
                    }}
                  >
                    https://microsistemas.com.br
                  </a>{" "}
                  ou entre em contato conosco via email{" "}
                  <a
                    href="mailto:service@microsistemas.com.br"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textTransform: "lowercase",
                      fontWeight: "bold",
                    }}
                  >
                    service@microsistemas.com.br
                  </a>{" "}
                  ou
                  <a
                    href="https://wa.me/message/E4EV63KYSO3ZP1"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    WhastApp: +55(41) 98848-5382
                  </a>
                  .
                </p>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      {/*<Footer />*/}
    </div>
  );
}

export default Support;
