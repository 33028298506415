import React, { useState, useEffect } from "react";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardContent,
  Typography,
  Divider,
  LinearProgress,
} from "@material-ui/core";

import moment from "moment";

// @material-ui/icons
import ErrorIcon from "@material-ui/icons/Error";

// custom components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Snackbar from "components/Snackbar/Snackbar.js";

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

import * as userToken from "functions/userToken";
import axios from "config/axios";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [logs, setLogs] = useState([]),
    [loading, setLoading] = useState(false),
    [alert, setAlert] = useState({ alert: false, msg: "" });

  /* UseEffect que carrega os logs */
  useEffect(() => {
    setLoading((oldState) => !oldState);
    axios
      .get("/user/logs")
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setLogs(data);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        switch (error?.response?.data?.status) {
          default:
            setAlert({ alert: true, msg: "Problema ao carregar histórico" });
            break;
        }
        console.log(error);
      });
  }, []);

  const procVoucherUnity = (item) => {
    let { unity } = item?.voucher;
    switch (unity) {
      case "minute":
        return "minuto(s)";
      case "hour":
        return "hora(s)";
      default:
        return "minuto(s)";
    }
  };

  return (
    <div>
      {loading && <LinearProgress />}
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={alert.msg}
        open={alert.alert}
        closeNotification={() => setAlert({ alert: false, msg: "" })}
        close
      />
      <GridContainer>
        {logs.length === 0 && (
          <GridItem xs={12} style={{ marginTop: 20 }}>
            <Card className={classes.root}>
              <CardContent>
                <Typography variant="body1" align="center">
                  Sem histórico
                </Typography>
              </CardContent>
            </Card>
          </GridItem>
        )}
        {logs.map((item, index) => (
          <GridItem xs={12} style={{ marginTop: 20 }} key={index}>
            <Card className={classes.root}>
              <CardContent>
                <Typography variant="body2">Estacionamento</Typography>
                <Typography variant="h4" style={{ fontSize: "4vh" }}>
                  {item?.park?.name}
                </Typography>
                <Divider style={{ marginTop: 5 }} />
                <div className={classes.dataContainer}>
                  <Typography variant="body1">
                    Valor pago:{" "}
                    {item?.monthlyUser ? (
                      <b>Mensalista</b>
                    ) : (
                      <b>
                        {new Intl.NumberFormat("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        }).format(item?.finalPrice || 0)}
                      </b>
                    )}
                  </Typography>
                  <Typography variant="body1">
                    Entrada:{" "}
                    <b>{moment(item?.in).format("DD/MM/YYYY HH:mm")}</b>
                  </Typography>
                  <Typography variant="body1">
                    Saída: <b>{moment(item?.out).format("DD/MM/YYYY HH:mm")}</b>
                  </Typography>
                  {item?.promotion && (
                    <Typography variant="body1">
                      <b>
                        Promoção: {(item?.promotion?.value * 100).toFixed(0)}%
                      </b>
                    </Typography>
                  )}
                  {item?.voucher && (
                    <Typography variant="body1">
                      Voucher:{" "}
                      <b>{`${item?.voucher?.value} ${procVoucherUnity(
                        item
                      )}`}</b>
                    </Typography>
                  )}
                </div>
              </CardContent>
            </Card>
          </GridItem>
        ))}
      </GridContainer>
    </div>
  );
}
