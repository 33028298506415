import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Button,
  Card,
  CardContent,
  Typography,
  FormControl,
  Input,
  InputLabel,
  FormGroup,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import fire from "config/firebase";

import ErrorIcon from "@material-ui/icons/Error";

import image from "assets/img/bg.jpg";

//custom components
import Snackbar from "components/Snackbar/Snackbar.js";

import useStyles from "./styles";

function ForgotPassword() {
  let [loading, setLoading] = useState(false),
    [email, setEmail] = useState(""),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    });

  let history = useHistory(),
    classes = useStyles();

  const verify = async (e) => {
    e.preventDefault();
    if (!email) {
      window.scrollTo(0, 0);
      return setAlert({ msg: "Insira o email", alert: true });
    }
    setLoading(true);
    fire
      .passwordReset(email)
      .then(() => {
        history.push("/", { resetSent: true });
      })
      .catch((error) => {
        setLoading(false);
        let { code } = error;
        switch (code) {
          case "auth/user-not-found":
            setAlert({
              alert: true,
              msg: "Usuário não encontrado! CADASTRE-SE no Park Lockyt",
            });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao pedir troca de senha",
            });
            break;
        }
      });
  };

  return (
    <div
      className={classes.backgroundImage}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <div>
        {loading ? <LinearProgress color="secondary" /> : <></>}
        <Snackbar
          place="tc"
          color="danger"
          icon={ErrorIcon}
          message={alert.msg}
          open={alert.alert}
          closeNotification={() => setAlert({ alert: false, msg: "" })}
          close
        />
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
          className={classes.logos}
        >
          <Grid item className={classes.gridLogos}>
            <a
              href="https://park.lockyt.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("assets/img/zigpark.png")}
                alt="Logomarca park lockyt"
                className={classes.logoLockyt}
              />
            </a>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <Card className={classes.root}>
              <CardContent>
                <form onSubmit={verify}>
                  <div style={{ textAlign: "center" }}>
                    <Typography variant="h4" style={{ marginBottom: "1rem" }}>
                      Troca de senha
                    </Typography>
                  </div>
                  <FormGroup>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="email">Email </InputLabel>
                      <Input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormControl>

                    <Button size="small" className={classes.btn} type="submit">
                      Pedir troca
                    </Button>
                    <div className={classes.btnSecondaryContainer}>
                      <Button
                        size="small"
                        className={classes.btnSecondary}
                        onClick={() => history.push("/")}
                      >
                        Voltar ao login
                      </Button>
                    </div>
                  </FormGroup>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      {/*<Footer />*/}
    </div>
  );
}

export default ForgotPassword;
