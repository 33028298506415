import Axios from "axios";
import * as userToken from "functions/userToken";

let host = "https://apipark.lockyt.com.br";

if (process.env.NODE_ENV === "development") {
  host = "http://localhost:8080";
  //host = "http://192.168.100.9:8080";
}
let api = Axios.create({
  baseURL: host,
});

api.interceptors.request.use(async (config) => {
  const token = userToken.get();
  if (token != null) {
    config.headers.authorization = `Bearer ${token}`;
  }
  return config;
});

export default api;
