import React, { useState, useEffect } from "react";

import {
  Typography,
  Grid,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Avatar,
  Button,
  CircularProgress,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ArrowBack from "@material-ui/icons/ArrowBack";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";

import Snackbar from "components/Snackbar/Snackbar.js";

import axios from "config/axios";

import useStyles from "./styles";

import { useHistory } from "react-router-dom";

function Profile() {
  const [data, setData] = useState(),
    [alert, setAlert] = useState(""),
    [alertSuccess, setAlertSuccess] = useState(""),
    [loading, setLoading] = useState(false),
    [birthdate, setBirthdate] = useState(null),
    [gender, setGender] = useState("");
  const classes = useStyles();
  const history = useHistory();
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);

  let isMobile = window.innerWidth <= 900;

  const goBack = async () => {
    history.push("/admin/dashboard");
  };

  const handleGenderChange = async (e) => {
    setGender(e.target.value);
  };

  const getData = async () => {
    setLoading((oldState) => !oldState);
    axios
      .get("/user/info")
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setData(data);
        setGender(data.gender ? data.gender : "none");
        setBirthdate(data.birthdate ? data.birthdate : null);
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.error) {
          default:
            setAlert("Problema ao ler dados");
            break;
        }
      });
  };

  const updateProfile = async () => {
    setLoading((oldState) => !oldState);
    axios
      .put("/user/info", {
        gender,
        birthdate,
      })
      .then(({ data }) => {
        setLoading((oldState) => !oldState);
        setData(data);
        setGender(data.gender ? data.gender : "none");
        setAlertSuccess("Perfil atualizado com sucesso");
      })
      .catch((error) => {
        setLoading((oldState) => !oldState);
        console.log(error);
        switch (error?.response?.data?.error) {
          default:
            setAlert("Problema ao atualizar dados");
            break;
        }
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", forceUpdate);
  }, [forceUpdate]);

  return (
    <>
      <Snackbar
        place="tc"
        color="danger"
        icon={ErrorIcon}
        message={alert ? alert : ""}
        open={alert ? true : false}
        closeNotification={() => setAlert("")}
        close
      />
      <Snackbar
        place="tc"
        color="success"
        icon={InfoIcon}
        message={alertSuccess ? alertSuccess : ""}
        open={alertSuccess ? true : false}
        closeNotification={() => setAlertSuccess("")}
        close
      />
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className={classes.container}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item>
              <IconButton onClick={goBack} className={classes.iconBtn}>
                <ArrowBack style={{ fontSize: 20 }} color="primary" />
              </IconButton>
            </Grid>
            <Grid item style={{ marginTop: 0 }}>
              <Typography variant="h1" className={classes.title}>
                Perfil
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={3}
            style={{ marginTop: 10 }}
          >
            <Grid item>
              <Avatar
                alt={data?.name}
                className={classes.avatar}
                onClick={() => console.log("asd")}
              />
            </Grid>
            <Grid item style={{ marginTop: 17 }}>
              <Typography variant="body1" style={{ fontSize: 18.5 }}>
                {data?.name}
              </Typography>
            </Grid>
            <Grid item className={isMobile ? null : classes.gridItem}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Gênero</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  value={gender}
                  onChange={handleGenderChange}
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Feminino"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Masculino"
                  />
                  <FormControlLabel
                    value="other"
                    control={<Radio />}
                    label="Outro"
                  />
                  <FormControlLabel
                    value="none"
                    control={<Radio />}
                    label="Não quero informar"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={isMobile ? 12 : 4}>
              <KeyboardDatePicker
                fullWidth
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                label="Data de nascimento"
                maxDate={`${new Date().getFullYear() - 18}/12/31`}
                value={birthdate}
                onChange={(date) => {
                  try {
                    date.setHours(0);
                    date.setMinutes(1);
                    setBirthdate(date);
                  } catch (error) {
                    return;
                  }
                }}
                KeyboardButtonProps={{
                  "aria-label": "Data de nascimento",
                }}
              />
            </Grid>
          </Grid>
          <div className={classes.actions}>
            <div className={classes.wrapper}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => updateProfile()}
                disabled={loading}
              >
                Salvar
              </Button>
              {loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </>
  );
}

export default Profile;
