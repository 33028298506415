import React, { useState } from "react";
import {
  Grid,
  LinearProgress,
  Button,
  Card,
  CardContent,
  Typography,
  FormControl,
  Input,
  InputLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import axios from "config/axios";
import fire from "config/firebase";

import isEmail from "functions/isEmail";
import validaCPF from "functions/validateCPF";

import ErrorIcon from "@material-ui/icons/Error";

import image from "assets/img/bg.jpg";

//custom components
import Snackbar from "components/Snackbar/Snackbar.js";
import InstallPWA from "components/InstallPWA/InstallPWA";

import useStyles from "./styles";

function Register() {
  let [loading, setLoading] = useState(false),
    [email, setEmail] = useState(""),
    [cellphone, setCellphone] = useState(""),
    [name, setName] = useState(""),
    [document, setDocument] = useState(""),
    [confPassword, setConfPassword] = useState(""),
    [alert, setAlert] = useState({
      alert: false,
      msg: "",
    }),
    [checked, setChecked] = useState(false),
    [password, setPassword] = useState("");

  let history = useHistory(),
    classes = useStyles();

  const verify = async (e) => {
    e.preventDefault();
    if (
      !document ||
      !name ||
      !password ||
      !cellphone ||
      !email ||
      !confPassword
    ) {
      window.scrollTo(0, 0);
      return setAlert({ msg: "Campos faltando", alert: true });
    }

    if (!checked) {
      window.scrollTo(0, 0);
      return setAlert({
        msg:
          "Para continuar aceite os termos de uso e políticas de privacidade",
        alert: true,
      });
    }

    if (!(await validaCPF(document))) {
      window.scrollTo(0, 0);
      return setAlert({ msg: "CPF inválido", alert: true });
    }

    if (!isEmail(email)) {
      window.scrollTo(0, 0);
      return setAlert({ msg: "Email inválido", alert: true });
    }

    if (password !== confPassword) {
      window.scrollTo(0, 0);
      return setAlert({ alert: true, msg: "As senhas não são iguais" });
    }
    let realCell = cellphone.replace(/\D/g, "");
    if (realCell.length < 11) {
      window.scrollTo(0, 0);
      return setAlert({ alert: true, msg: "Telefone inválido" });
    }
    setLoading(true);
    fire
      .emailRegister(email, password)
      .then((user) => {
        fire
          .sendVerifyEmail()
          .then(async () => {
            axios
              .post("/register", {
                email,
                name,
                cellphone: "+55" + realCell,
                firebaseUid: user.user.uid,
                document: await validaCPF(document),
              })
              .then(() => {
                setLoading(false);
                history.push("/", { emailSent: true });
              })
              .catch((error) => {
                window.scrollTo(0, 0);
                setLoading(false);
                console.log(error);
                setAlert({
                  alert: true,
                  msg: "Problema ao registrar, tente novamente",
                });
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        setLoading(false);
        window.scrollTo(0, 0);
        let { code } = error;
        switch (code) {
          case "auth/weak-password":
            setAlert({
              alert: true,
              msg: "Senha muito fraca",
            });
            break;
          case "auth/email-already-in-use":
            setAlert({
              alert: true,
              msg: "Email já em uso",
            });
            break;
          case "auth/invalid-email":
            setAlert({
              alert: true,
              msg: "Email inválido",
            });
            break;
          default:
            setAlert({
              alert: true,
              msg: "Problema ao registrar",
            });
            break;
        }
      });
  };

  const maskCel = async (e) => {
    let v = e.target.value;
    v = v.replace(/\D/g, ""); //Remove tudo o que não é dígito
    if (v.length > 11) {
      return;
    }
    v = v.replace(/^(\d{2})(\d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    v = v.replace(/(\d)(\d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
    setCellphone(v);
  };

  return (
    <div
      className={classes.backgroundImage}
      style={{
        backgroundImage: "url(" + image + ")",
        backgroundSize: "cover",
        backgroundPosition: "top center",
      }}
    >
      <div>
        {loading ? <LinearProgress color="secondary" /> : <></>}
        <Snackbar
          place="tc"
          color="danger"
          icon={ErrorIcon}
          message={alert.msg}
          open={alert.alert}
          closeNotification={() => setAlert({ alert: false, msg: "" })}
          close
        />
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          className={classes.logos}
        >
          <Grid item className={classes.gridLogos}>
            <a
              href="https://park.lockyt.com.br"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require("assets/img/zigpark.png")}
                alt="Logomarca park lockyt"
                className={classes.logoLockyt}
              />
            </a>
          </Grid>
          <Grid item style={{ marginRight: "2%" }}>
            <InstallPWA btn={{ className: classes.installPWA }} />
          </Grid>
        </Grid>
        <Grid container direction="row" justify="center" alignItems="center">
          <Grid item>
            <Card className={classes.root}>
              <CardContent>
                <form onSubmit={verify}>
                  <div style={{ textAlign: "center" }}>
                    <Typography variant="h4" style={{ marginBottom: "1rem" }}>
                      Cadastrar-se
                    </Typography>
                  </div>
                  <FormGroup>
                    <FormControl className={classes.input}>
                      <InputLabel>Nome completo </InputLabel>
                      <Input
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </FormControl>
                    <FormControl className={classes.input}>
                      <InputLabel>CPF </InputLabel>
                      <Input
                        id="cpf"
                        value={document}
                        onChange={(e) => setDocument(e.target.value)}
                      />
                    </FormControl>
                    <FormControl className={classes.input}>
                      <InputLabel>Telefone</InputLabel>
                      <Input
                        id="cellphone"
                        value={cellphone}
                        onChange={(e) => maskCel(e)}
                      />
                    </FormControl>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="email">Email </InputLabel>
                      <Input
                        id="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormControl>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="password">Senha </InputLabel>
                      <Input
                        id="password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </FormControl>
                    <FormControl className={classes.input}>
                      <InputLabel htmlFor="confPassword">
                        Confirme sua senha
                      </InputLabel>
                      <Input
                        id="confPassword"
                        type="password"
                        value={confPassword}
                        onChange={(e) => setConfPassword(e.target.value)}
                      />
                    </FormControl>
                    <FormControlLabel
                      style={{ textAlign: "center" }}
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={() => setChecked(!checked)}
                          value="checkedA"
                        />
                      }
                      label={
                        <div>
                          <p>
                            Aceito os{" "}
                            <a
                              href={`${window.location.origin}/termos/termo_de_uso.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              termos de uso
                            </a>{" "}
                            e{" "}
                            <a
                              href={`${window.location.origin}/termos/politica_de_privacidade.pdf`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              políticas de privacidade
                            </a>
                          </p>
                        </div>
                      }
                    />
                    <Button size="small" className={classes.btn} type="submit">
                      Cadastrar-se
                    </Button>
                    <div className={classes.btnSecondaryContainer}>
                      <Typography
                        variant="caption"
                        display="block"
                        gutterBottom
                      >
                        ou
                      </Typography>
                      <Button
                        size="small"
                        className={classes.btnSecondary}
                        onClick={() => history.push("/")}
                      >
                        Entrar
                      </Button>
                    </div>
                  </FormGroup>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
      {/*<Footer />*/}
    </div>
  );
}

export default Register;
